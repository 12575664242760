import React from 'react';
import NutritionHubPage from '../../components/nutrition-hub';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const NutritionHub = () => {
    return (
        <Layout>
            <SEO
                title="Nutrition Hub | J Clarke Fitness"
                description="My brand new Transformation Program is finally here and it is bigger and better than ever before!"
            />
            <NutritionHubPage predefinedPrice={89} />
        </Layout>
    );
};
export default NutritionHub;
